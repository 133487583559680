<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
}
</script>
<style lang="scss">
.navbar-nav {
  background-color: #F5F3F2;
  height: 36px;
  margin-top:10px;
  li {
    line-height: 1 !important;
  }
}

$mobile-menu-width: 75px;

ul#main-menu-navigation {
   display: flex;
   border-radius: 10px !important;
    & > li {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 36px !important;
    margin-top: 0 !important;
    overflow: hidden !important;
    width: fit-content !important;
    box-shadow: none;
    border-radius: 10px !important;
    .menu-title {
      margin-top: -2px !important;
    }
    &:not(.disabled):hover {
      a {
        background: #E7E2E0 !important;
      }
    }
    &.disabled {
      a {
        cursor: not-allowed !important;
      }

      img{
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }

      &:hover {
        a {
          background: transparent !important;        
        }
      }
      }
     &.active {
      color: #6e6b7b;
      background: #151210 !important;
      img:not(.issues-class) {
        filter: invert(100%);
        -webkit-filter: invert(100%);
         }
     a:not(.dropdown-item){
        color: #0000;
        background: #151210 !important;
        box-shadow: none !important;
      }
    }
    &:not(:last-child) {
      margin-right: 14px;
    }
    a{
      padding: 9px 10px 12px 10px;
      border-radius: 10px !important;
      margin-right: 0 !important;
      padding: 0rem;
      .custom-image-url {
        margin-top: -5px;
        margin-right: 2px;
        width: 24px;
        height: 24px;
      }
    }
  }
}



  @media screen and (max-width: 1200px) {
    .horizontal-menu-wrapper {
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      z-index: 10000000 !important;
      width: 100% !important;
      height: 60px !important;
      .header-navbar  {
        .navbar-container {
          height: 60px !important;
          padding: 0px !important
        }
      }
    }

    html[dir] .navbar-hidden .app-content {
      padding: 0px 1rem 0 1rem !important;
    }

    ul#main-menu-navigation {
      & > li {
         margin-right: 0 !important;
        .menu-title {
          display: none;
        }
        a {
          padding: 9px 10px 12px 10px;
        }
      }
    }

    footer.footer {
      padding: 0px 20px 102px 22px !important;
    }
    .user-name {
      display: none !important;
    }

    [dir] .navbar-container .nav-profile-menu .b-dropdown.show {
      border: none !important;
      width: 75px !important;
      min-width: 75px !important;

    }

    .navbar-container {
      .nav-profile-menu {
        width: $mobile-menu-width !important;
        min-width: $mobile-menu-width !important;
        .dropdown-toggle {
          width: $mobile-menu-width !important;
          min-width: $mobile-menu-width !important;
          .b-dropdown {
            width: $mobile-menu-width !important;
            min-width: $mobile-menu-width !important;
            &.show {
              width: 60px !important;
              min-width: 60px !important;
              .dropdown-toggle {
                width: $mobile-menu-width !important;
                min-width: $mobile-menu-width !important;
              }
            }
          }
        }
    }
  }

  }

  @media screen and (max-width: 450px) {
      ul#main-menu-navigation {
      border-radius: 10px !important;
        & > li {
          a{
            padding: 0.8rem !important;
          }
      }
    }
}

@media screen and (max-width: 360px) {
      ul#main-menu-navigation {
        & > li {
          a{
            padding: 12px 10px !important;
        }
      }
    }

  .custom-image-url {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 280px) {
      ul#main-menu-navigation {
        & > li {
          a{
            padding: 12px 7px !important;
        }
      }
    }

  .custom-image-url {
    width: 15px;
    height: 15px;
  }
}

</style>
