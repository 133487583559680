<template>
  <div class="navbar-container main-menu-content">


    <div
      class="home-menu nav-profile-menu float-right"
    >

    <div class="custom-dropdown" @mouseenter="toggleTopMenu" @mouseleave="toggleTopMenu"  @focus="toggleTopMenu" @focusout="toggleTopMenu">
      <b-row @click="toggleTopMenu" :class="{ 'active-menu': showTopMenu}" class="dropdown-button-content">
              <div>
                <img
                  class="user-icon"
                  src="@/assets/images/icons/general-icons/user-icon.png"
                  size="40px"
                >
              </div>
              <b-col>
                <div class="user-name">
                  {{ userData.name }}
                  <div class="caret-icon" />
                </div>
              </b-col>
        </b-row>

        <ul class="dropdown-items list-unstyled" v-if="showTopMenu">
            <li class="preferences-tab">
              <div @click="openPreferencesModal">
                    <img
                      class="user-icon"
                      src="@/assets/images/icons/menu-icon/perferences-icon.svg"
                    >
                    Preferences
              </div>
            </li>
            <li>
              <div @click="logout">
                    <img
                      class="user-icon"
                      src="@/assets/images/icons/menu-icon/signout-icon.svg"
                    >
                    Sign out
                  </div>
            </li>
        </ul>

    </div>

    </div>


    <div
      class="horizontal-nav-menu-items-wrapper"
    >
      <horizontal-nav-menu-items :items="navMenuItems" />
    </div>

    <b-modal
      id="preferencesModal"
      ref="preferencesModal"
      size="lg"
      @show="getTab('admins')"
    >
      <div
        v-b-tooltip="'Close'"
        class="closer"
        @click="closePreferencesModal"
      >
        <img
          class="close-icon-url"
          :src="require(`@/assets/images/icons/menu-icon/popup-icons/close-icon.svg`)"
        >
      </div>
      <b-tabs
        pills
        card
        vertical
      >
        <b-tab disabled>
          <template slot="title">
            <img
              class="custom-icon-url user-icon-heading"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/user-icon.svg`)"
            >
            {{ userData.name }}
          </template>
        </b-tab>
        <b-tab
          class="adminsTab"
          active
          lazy
          @click="getTab('admins')"
        >
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/admins-icon.svg`)"
            >
            Admins
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>

        <b-tab
          lazy
          @click="getTab('roles')"
        >
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/roles-icon.svg`)"
            >
            Roles
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>
        <b-tab
          lazy
          @click="getTab('service-locators')"
        >
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/map-icon.svg`)"
            >
            Service Locators
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>
        <b-tab
          lazy
          @click="getTab('configurator')"
        >
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/configurator-icon.svg`)"
            >
            Configurator
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>

        <b-tab 
          @click="getTab('logs')"
          lazy
        >
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/logs-icon.svg`)"
            >
            Logs
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>

        <b-tab 
          @click="getTab('admin-logs')"
        lazy>
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/logs-icon.svg`)"
            >
            Admin logs
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>

        <b-tab lazy>
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/backup-icon.svg`)"
            >
            Backup
          </template>
          <b-card-text><b>Backup contents under development</b></b-card-text>
        </b-tab>

        <b-tab
          lazy
          @click="getTab('release-notes')"
        >
          <template slot="title">
            <img
              class="custom-icon-url"
              :src="require(`@/assets/images/icons/menu-icon/popup-icons/about-icon.svg`)"
            >
            About
          </template>
          <b-card-text>
            <router-view name="PreferencesRouterView" />
          </b-card-text>
        </b-tab>
      </b-tabs>

    </b-modal>

  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import useJwt from '@/auth/jwt/useJwt'
import { VBTooltip } from 'bootstrap-vue'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
    useJwt,
    VBTooltip,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      showTopMenu: false,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('permissions')
      this.$router.push({ name: 'auth-login' })
    },
    openPreferencesModal() {
      localStorage.setItem('before-opens-modal-pathname', window.location.pathname)
      this.$refs.preferencesModal.show()
    },
    closePreferencesModal() {
      const pathname = localStorage.getItem('before-opens-modal-pathname')
      localStorage.removeItem('before-opens-modal-pathname')
      if (pathname) {
        this.$router.push({ path: pathname })
      }
      this.$refs.preferencesModal.hide()
    },
    getTab(tab) {
      this.$router.push({ name: tab, restrict: true })
    },
    toggleTopMenu() {
      this.showTopMenu = !this.showTopMenu
    }
  },
}

</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
// vars
$dropdown-width: 190px;

// maxins
@mixin nav-link-style {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      padding: 4px 6px;
      gap: 6px;
      min-width: 200px;
      height: 32px;
      font-weight: 500;
      font-size: 15px;
      color: #373533;
      &.active {
        background: #F2F2F2;
        border:none;
        color: #373533;
        box-shadow: none;
      }
      &.disabled {
        color: #373533;
        .custom-icon-url {
          width: 18px;
          height: 18px;
        }
      }
}



.custom-dropdown {

  top:0px !important;
  left: -1px !important;
  position: relative;
  cursor: pointer;
  padding: 0px 10px 0 0px !important;
  .active-menu {
    animation: none !important;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-bottom: none !important;
    padding-left: 5px;
  }
  ul.dropdown-items {
    width: -moz-available !important;
    width: -webkit-fill-available !important;
    width: fill-available !important;
    width: 102% !important;
    background-color: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.08) !important;
    border-radius: 0px 0px 8px 8px;
    border-top: none !important;
    position: absolute;
    li {
      div {
        padding:10px 17px 10px 15px !important;

          .user-icon {
            margin-right: 5px !important;
            border-radius: 0 !important;
          }
        }
        &:focus,:hover {
          background-color: #1e1e1e;
          color: #ffffff;
          .user-icon {
            -webkit-filter: invert(100%);
            filter: invert(100%);
          }
        }
    }
  }
}

.horizontal-nav-menu-items-wrapper {
  margin:auto !important;
}

.user-icon-heading {
  margin-left: 3px;
}
.navbar-container {
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  .navbar-nav {
    justify-content: space-between;
    a {
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
  .logout-item {
    padding: 15px;
    margin-top:5px;
    cursor: pointer;
  }
  .user-name {
    color: black;
  }
  .nav-profile-menu {
         padding: 10px !important;
        .b-dropdown{
          width: $dropdown-width !important;
          min-width: $dropdown-width !important;
          &.show {
          width: $dropdown-width !important;
          min-width: $dropdown-width !important;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: none;
          border-radius: 8px 8px 0px 0px;
          .caret-icon {
            transform: rotate(180deg);
            margin-right: -5px;
            margin-top: -3px;
          }
        }
        .dropdown-item {
          padding:10px 15px !important;
          .user-icon {
            margin-right: 5px !important;
            border-radius: 0 !important;
          }
          &:focus {
            background-color: #1e1e1e;
            color: #ffffff;
            .user-icon {
              -webkit-filter: invert(100%);
              filter: invert(100%);
            }
          }
        }
      }
    .dropdown-button-content {
      padding: 7px 5px 3px 7px;
      align-items: center;
      text-align: left;
      margin-left: 0px;
      padding: 7px 0;
    }
    .dropdown-menu{
      width: $dropdown-width !important;
      min-width: $dropdown-width !important;
      top:0px !important;
      left: -1px !important;
      animation: none !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-top: none !important;
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.08) !important;
      border-radius: 0px 0px 8px 8px;
      border-top: none !important;
      border-top: 1px solid #EAEAEA !important;
    }
    .dropdown-toggle {
      padding:0px 8px;
    }
    .caret-icon {
      background-image: url('~@/assets/images/icons/menu-icon/caret-icon.svg');
      background-repeat: no-repeat;
      display: block;
      width: 20px;
      height: 20px;
      margin-top: -5px;
      float: right;
    }
  }
}
#preferencesModal {
    // padding: 10px;
    justify-content: center;
    .custom-icon-url {
      width: 24px;
      height: 24px;
    }

    .modal-dialog {
      margin:auto;
      position: absolute;
      left: 75px;
      top: 20px
    }

    //  margin-top:100px !important;
     .modal-content {
      height:95svh;
      overflow-y: scroll;
      min-width:90svw;
      }
    .modal-header {
      display: none !important;
    }
    .modal-footer {
      display: none !important;
    }
    .modal-body {
      margin: auto;
      .closer {
        float: right;
        cursor:pointer;
        width: 25px;
        height: 25px;
        text-align: center;
        vertical-align: middle;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
      padding: 5px;
      .username {
        padding: 8px;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        @include nav-link-style;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .tabs  {
        height: 90vh;
        padding-top: 10px;
        min-width: 88vw;
        .col-auto {
          height: 100%;
          .nav-pills {
            padding:0px 8px;
            background-color: #FBFBFB;
            color: #FBFBFB;
            border:none;
            margin-bottom: 0px;
            .nav-link {
              @include nav-link-style
            }
          }
        }
      }
    }
}

@media screen and (max-width: 1200px) {
  .nav-profile-menu {
        .dropdown-menu {
          border-radius: 8px 8px 0px 0px !important;
          li:nth-child(1) {
            display: none !important;
          }
        }
      }

  #preferencesModal {
      justify-content: center;
        .custom-icon-url {
          width: 24px;
          height: 24px;
        }
  }

  .custom-dropdown {
    ul.dropdown-items {
      position: absolute !important;
      bottom: 20px !important;
      width: 135px !important;
      border-top: 1px;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 8px 8px 0px 0px;
      border-bottom: none !important;
    }
    .preferences-tab {
      display: none;
    }
  } 
}
</style>
